import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/Text';

import { Field, FieldPrivacy } from '../../../../../../types';
import { LockIcon, PublicIcon } from '../../../common/icons';
import { classes } from './RadioButtonContent.st.css';

type Props = {
  label: string;
  subLabel: string;
  fieldPrivacy: Field['privacy'];
};

const icons = {
  [FieldPrivacy.PRIVATE]: <LockIcon />,
  [FieldPrivacy.PUBLIC]: <PublicIcon />,
  [FieldPrivacy.UNKNOWN]: <PublicIcon />,
};

export const RadioButtonContent: FC<Props> = ({
  label,
  subLabel,
  fieldPrivacy,
}) => (
  <div className={classes.container}>
    <div className={classes.textContainer}>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.subLabel}>{subLabel}</Text>
    </div>
    {icons[fieldPrivacy]}
  </div>
);
