import React, { FC, ReactElement, useState } from 'react';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import { ReactComponent as CaretDown } from 'wix-ui-tpa/dist/src/assets/icons/CaretDown.svg';

import { classes, st } from './Item.st.css';
import { Divider } from '../../Divider';
import { CaretUp } from '../../icons';

type Props = {
  header: ReactElement;
  onClick?: () => void;
  isInitiallyOpen?: boolean;
  className?: string;
  'data-hook'?: string;
};

export const Item: FC<Props> = (props) => {
  const {
    header,
    children,
    className,
    'data-hook': dataHook,
    isInitiallyOpen = false,
    onClick,
  } = props;
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const onAccordionItemClick = () => {
    onClick?.();
    setIsOpen(!isOpen);
  };

  return (
    <div className={st(classes.root, className)} data-hook={dataHook}>
      <div className={classes.header} onClick={onAccordionItemClick}>
        {header}
        <IconButton
          className={classes.iconButton}
          skin={Skins.Line}
          icon={isOpen ? <CaretUp /> : <CaretDown />}
        />
      </div>
      <div className={st(classes.content, { open: isOpen })}>{children}</div>
      <Divider />
    </div>
  );
};
