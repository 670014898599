import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Formik } from 'formik';
import { debounce } from 'lodash';

import { getValidationSchema } from './validationSchema';
import { Form } from './Form';
import { useFields, useFieldsActions } from '../../../../contexts/widget';
import { useBi } from '../../../../hooks/useBi';
import { Field } from '../../../../types';
import { getFieldsWithChangedPrivacy, getChangedFields } from './changedFields';

const SUBMIT_DEBOUNCE_TIMEOUT = 1000;

export const AccountForm: FC = ({ children }) => {
  const initialFields = useFields();
  const [validateOnBlur, setValidationOnBlur] = useState(false);
  const { t } = useTranslation();
  const { updateFields } = useFieldsActions();
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const biLogger = useBi();

  const onSubmit = useMemo(
    () =>
      debounce(
        (current: Field[], initial: Field[], bi: ReturnType<typeof useBi>) => {
          setValidationOnBlur(true);
          const changedFields = getChangedFields(current, initial);
          const changedPrivacyFields = getFieldsWithChangedPrivacy(
            current,
            initial,
          );
          updateFields({ fields: current, changedPrivacyFields });
          bi.profileEdited({ changedFields, changedPrivacyFields });
        },
        SUBMIT_DEBOUNCE_TIMEOUT,
        { leading: true },
      ),
    [updateFields],
  );

  return (
    <Formik
      initialValues={initialFields}
      onSubmit={(currentFields) =>
        onSubmit(currentFields, initialFields, biLogger)
      }
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={validateOnBlur}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  );
};
