import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { classes, st } from './Root.st.css';
import { DataHook } from '../../../../constants/DataHook';
import { Header } from '../Header';
import { Divider } from '../common/Divider';
import {
  AccountSettings,
  isAccountSettingEmpty,
} from '../Sections/AccountSettings';
import { AccountForm } from '../AccountForm';
import { PublicInfo } from '../Sections/PublicInfo';
import { Account } from '../Sections/Account';
import { SocialLinks } from '../Sections/SocialLinks';
import settingsParams from '../../settingsParams';
import { Field, FieldSection, PageAlignment } from '../../../../types';
import { CTAGroup } from '../common/CTAGroup';
import {
  useMember,
  useFields,
  useSiteContext,
} from '../../../../contexts/widget';
import { Toast } from '../Toast';
import { AddressSection } from '../Sections/Address';

const useFieldPresence = (fields: Field[]) => {
  const hasDisplayInfoFields = fields.some(
    (field) => field.section === FieldSection.DISPLAY_INFO,
  );
  const hasAccountFields = fields.some(
    (field) => field.section === FieldSection.GENERAL,
  );
  const hasAddressFields = fields.some(
    (field) => field.section === FieldSection.ADDRESS,
  );
  const hasSocialFields = fields.some(
    (field) => field.section === FieldSection.SOCIAL,
  );

  return {
    hasDisplayInfoFields,
    hasAccountFields,
    hasAddressFields,
    hasSocialFields,
    hasAnyFields: hasAccountFields || hasAddressFields || hasSocialFields,
  };
};

export const Root: FC = () => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const { privacyStatus, role } = useMember();
  const fields = useFields();
  const {
    hasAnyFields,
    hasDisplayInfoFields,
    hasAccountFields,
    hasAddressFields,
    hasSocialFields,
  } = useFieldPresence(fields);
  const { isSocial, isCustomProfileEnabled } = useSiteContext();

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;
  const isPageLayoutRtl =
    pageAlignment === PageAlignment.right ? !isRTL : isRTL;
  const isMobileCustomProfile = isMobile && isCustomProfileEnabled;
  const shouldShowAccountSettings =
    isSocial &&
    !isAccountSettingEmpty(privacyStatus, role, isCustomProfileEnabled);
  const shouldShowCTAGroup =
    hasAnyFields || (isMobileCustomProfile && hasDisplayInfoFields);

  return (
    <>
      <Toast />
      <div
        className={st(classes.root, { alignRight: isPageLayoutRtl })}
        data-hook={DataHook.WidgetWrapper}
        dir={isPageLayoutRtl ? 'rtl' : 'ltr'}
      >
        <AccountForm>
          {!isMobile && (
            <>
              <Header />
              <Divider />
            </>
          )}
          <div
            className={st(classes.sections, {
              alignCenter: pageAlignment === PageAlignment.center,
            })}
          >
            {isCustomProfileEnabled && <PublicInfo />}
            {hasAnyFields && isCustomProfileEnabled && <Divider />}
            {hasAccountFields && <Account />}
            {hasAddressFields && <AddressSection />}
            {hasSocialFields && <SocialLinks />}
            {shouldShowCTAGroup && (
              <div className={classes.ctaGroup}>
                <CTAGroup />
              </div>
            )}
            {shouldShowAccountSettings && <Divider />}
          </div>
        </AccountForm>
        {shouldShowAccountSettings && <AccountSettings />}
      </div>
    </>
  );
};
