import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  pageBgColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  fieldLabelColor: StyleParamType.Color;
  inputFieldsColor: StyleParamType.Color;
  fieldBorderColor: StyleParamType.Color;
  inputFieldsFont: StyleParamType.Font;
  fieldBorderWidth: StyleParamType.Number;
  fieldLabelFont: StyleParamType.Font;
  descriptionFont: StyleParamType.Font;
  descriptionColor: StyleParamType.Color;
  buttonTextFont: StyleParamType.Font;
  buttonBgColor: StyleParamType.Color;
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  fieldRowGap: StyleParamType.Number;
  fieldColGap: StyleParamType.Number;
  fieldsBgColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  settingsFont: StyleParamType.Font;
  settingsColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  customProfileEnabled: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  pageBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      // lineHeight: 16
    }),
  },
  fieldLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S', {
      size: 14,
      // lineHeight: 14
    }),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
      // lineHeight: 18
    }),
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  settingsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  settingsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 15,
      // lineHeight: 18
    }),
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 26,
      style: {
        bold: false,
        italic: false,
        underline: false,
      },
    }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
      style: {
        bold: false,
        italic: false,
        underline: false,
      },
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  fieldBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  fieldColGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  fieldsBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  customProfileEnabled: {
    type: StyleParamType.Boolean,
    key: 'customProfileEnabled',
    getDefaultValue: () => false,
  },
});
