import { useField as useFormikField } from 'formik';
import React, { FC } from 'react';
import { Dropdown as TPADropdown } from 'wix-ui-tpa/Dropdown';
import { DropdownOptionProps } from 'wix-ui-tpa/dist/src/components/Dropdown/DropdownOption';
import { TextFieldTheme } from 'wix-ui-tpa/TextField';

import { classes } from './Dropdown.st.css';
import { Field, FieldWrapper } from '../../../../../../types';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { DataHook } from '../../../../../../constants/DataHook';
import { useFieldLabel } from '../../../../../../hooks';

interface DropdownFieldProps extends FieldWrapper {
  options: DropdownOptionProps[];
}

export const DropdownField: FC<DropdownFieldProps> = ({
  formikFieldSelector,
  theme,
  options,
}) => {
  const { getFieldLabel } = useFieldLabel();
  const [formikField, , helpers] = useFormikField<Field>(formikFieldSelector);

  const field = formikField.value;
  const { required, infoTooltipText, value, key, id } = field;
  const dropdownClassName =
    theme === TextFieldTheme.Line
      ? `${classes.field} ${classes.materialField}`
      : classes.field;

  return (
    <div data-hook={key}>
      <FieldLabel
        text={getFieldLabel(field)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
      >
        <TPADropdown
          options={options}
          className={dropdownClassName}
          data-hook={DataHook.TextField}
          placeholder=""
          initialSelectedId={
            options.length && value.selectedId ? value.selectedId : ' '
          }
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: { ...value, selectedId: event.id },
            });
          }}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
