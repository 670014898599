import { useField as useFormikField } from 'formik';
import React, { FC, FocusEventHandler, useState } from 'react';
import { TextField } from 'wix-ui-tpa/TextField';

import { classes } from './Text.st.css';
import { Field, FieldWrapper } from '../../../../../../types';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { DataHook } from '../../../../../../constants/DataHook';
import { useFieldLabel } from '../../../../../../hooks';

type ErrorType = { value: string } | undefined;

interface TextProps extends FieldWrapper {
  pattern?: HTMLInputElement['pattern'];
  type?: HTMLInputElement['type'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Text: FC<TextProps> = ({
  theme,
  onBlur,
  formikFieldSelector,
  type,
  pattern,
}) => {
  const { getFieldLabel } = useFieldLabel();
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [formikField, meta, helpers] =
    useFormikField<Field>(formikFieldSelector);

  const error = (meta.error as unknown as ErrorType)?.value;
  const field = formikField.value;
  const { required, infoTooltipText, value, key, id } = field;

  return (
    <div data-hook={key}>
      <FieldLabel
        text={getFieldLabel(field)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
      >
        <TextField
          id={id}
          className={classes.field}
          data-hook={DataHook.TextField}
          value={
            value ??
            '' /* Need to pass empty string, for formik form reset to work*/
          }
          theme={theme}
          type={type ?? 'text'}
          required={required}
          pattern={pattern}
          errorMessage={error}
          withClearButton={isClearButtonVisible}
          error={!!error}
          onClear={() => helpers.setValue({ ...field, value: '' })}
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: event.target.value,
            });
            setClearButtonVisibility(!!event.target.value);
          }}
          onFocus={() => setClearButtonVisibility(!!value)}
          onBlur={(event) => {
            setTimeout(() => setClearButtonVisibility(false), 100);
            onBlur?.(event);
          }}
          errorTooltipMaxWidth={2000}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
