import React, { FC } from 'react';
import { Section } from '@wix/ambassador-members-custom-fields-api/types';

import { classes } from './AddressSection.st.css';
import { FieldGroup } from '../../FieldGroup';
import { useFields } from '../../../../../contexts/widget';

export const AddressSection: FC = () => {
  const fields = useFields();

  return (
    <div className={classes.root}>
      <FieldGroup fields={fields} section={Section.ADDRESS} />
    </div>
  );
};
