import React, { FC, useCallback } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/Text';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';

import { Accordion } from '../../../../common/Accordion';
import { classes } from './CommunityPageUrl.st.css';
import { useModal } from '../../../../../../../contexts/modals/hooks';
import { EditProfileUrlModal } from '../../../../modals';
import { DataHook } from '../../../../../../../constants/DataHook';
import {
  useMember,
  useSiteContext,
} from '../../../../../../../contexts/widget';
import { useBi } from '../../../../../../../hooks/useBi';
import { BiActionType } from '../../../../../../../types';

export const CommunityPageUrl: FC = () => {
  const { t } = useTranslation();
  const { baseUrl } = useSiteContext();
  const { slug } = useMember().profile;
  const { showModal } = useModal();
  const biLogger = useBi();

  const communityUrl = `${baseUrl}/profile/${slug}/profile`;

  const onEditProfileUrlClick = useCallback(() => {
    biLogger.ctaButtonClicked(BiActionType.Edit_Url);
    showModal(EditProfileUrlModal);
  }, [biLogger, showModal]);

  return (
    <Accordion.Item
      header={
        <Text
          className={classes.header}
          data-hook={DataHook.ProfileUrlAccordionHeader}
        >
          {t('app.widget.community-url.title')}
        </Text>
      }
    >
      <div>
        <Tooltip className={classes.tooltip} content={communityUrl}>
          <Text className={classes.content} tagName="div">
            {communityUrl}
          </Text>
        </Tooltip>
      </div>
      <TextButton
        className={classes.cta}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={onEditProfileUrlClick}
        data-hook={DataHook.EditProfileUrlButton}
      >
        {t('app.widget.community-url.cta')}
      </TextButton>
    </Accordion.Item>
  );
};
