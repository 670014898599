import React, { FC, FocusEventHandler } from 'react';
import { useField as useFormikField } from 'formik';

import { Text } from '../Text';
import { Field, FieldWrapper } from '../../../../../../types';

const hasProtocol = (url: string) => /^https?:\/\//i.test(url);
const addProtocol = (url: string) => `https://${url}`;
const withProtocol = (url: string) =>
  hasProtocol(url) ? url : addProtocol(url);

export const UrlField: FC<FieldWrapper> = (props) => {
  const [formikField, , helpers] = useFormikField<Field>(
    `${props.formikFieldSelector}`,
  );
  const field = formikField.value;

  const onBlur: FocusEventHandler<HTMLInputElement> = ({ target }) => {
    const urlWithProtocol = target.value && withProtocol(target.value);

    helpers.setValue({
      ...field,
      value: urlWithProtocol,
    });
  };

  return <Text {...props} onBlur={onBlur} type="url" pattern="https://.*" />;
};
