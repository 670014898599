import React, { FC } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { FieldGroup } from '../../FieldGroup';
import { SectionHeader } from '../../common/SectionHeader';
import { classes } from './PublicInfo.st.css';
import { useFields } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';
import { FieldSection } from '../../../../../types';

export const PublicInfo: FC = () => {
  const fields = useFields();
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <SectionHeader
        title={settings.get(settingsParams.publicInfoSectionTitle)}
        subtitle={settings.get(settingsParams.publicInfoSectionSubtitle)}
      />
      <FieldGroup fields={fields} section={FieldSection.DISPLAY_INFO} />
    </div>
  );
};
