import React, { FC, useCallback } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { Text } from 'wix-ui-tpa/Text';

import { Accordion } from '../../../../common/Accordion';
import { classes } from './ProfileVisibility.st.css';
import { useModal } from '../../../../../../../contexts/modals/hooks';
import { ProfilePrivacyModal } from '../../../../modals';
import { DataHook } from '../../../../../../../constants/DataHook';
import { useMember } from '../../../../../../../contexts/widget';
import { ProfilePrivacyStatus, BiActionType } from '../../../../../../../types';
import { useBi } from '../../../../../../../hooks/useBi';

export const ProfileVisibility: FC = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { privacyStatus } = useMember();
  const biLogger = useBi();

  const isProfilePublic = privacyStatus === ProfilePrivacyStatus.PUBLIC;

  const ctaText = isProfilePublic
    ? t('app.widget.change-privacy.make-private-cta')
    : t('app.widget.change-privacy.make-public-cta');

  const onChangeProfilePrivacyClick = useCallback(() => {
    if (isProfilePublic) {
      biLogger.leaveCommunityAttempt();
    } else {
      biLogger.joinCommunityAttempt();
    }
    showModal(ProfilePrivacyModal);
  }, [biLogger, showModal, isProfilePublic]);

  const onAccordionItemClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Visibility_Settings);
  };

  return (
    <Accordion.Item
      header={
        <Text
          className={classes.header}
          data-hook={DataHook.VisibilityAccordionHeader}
        >
          {t('app.widget.change-privacy.title')}
        </Text>
      }
      onClick={onAccordionItemClick}
    >
      <Text className={classes.content} tagName="div">
        {t('app.widget.change-privacy.subtitle')}
      </Text>
      <TextButton
        className={classes.cta}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={onChangeProfilePrivacyClick}
        data-hook={DataHook.ChangeProfilePrivacyButton}
      >
        {ctaText}
      </TextButton>
    </Accordion.Item>
  );
};
