import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { SectionHeader } from '../../common/SectionHeader';
import { classes, st } from './AccountSettings.st.css';
import { Accordion } from '../../common/Accordion';
import { CommunityPageUrl, ProfileVisibility } from './Settings';
import {
  MemberRole,
  PageAlignment,
  ProfilePrivacyStatus,
} from '../../../../../types';
import { useMember, useSiteContext } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';

const isPublicProfile = (privacyStatus: ProfilePrivacyStatus) => {
  return privacyStatus === ProfilePrivacyStatus.PUBLIC;
};

const isOwner = (role: MemberRole | undefined) => role === MemberRole.OWNER;

export const isAccountSettingEmpty = (
  privacyStatus: ProfilePrivacyStatus,
  role: MemberRole | undefined,
  isCustomProfileEnabled: boolean,
) => {
  const shouldHideProfileVisibility = isOwner(role) || !isCustomProfileEnabled;
  return !isPublicProfile(privacyStatus) && shouldHideProfileVisibility;
};

export const AccountSettings: FC = () => {
  const { t } = useTranslation();
  const { privacyStatus, role } = useMember();
  const { isCustomProfileEnabled } = useSiteContext();
  const settings = useSettings();

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;

  return (
    <div
      className={st(classes.root, {
        alignCenter: pageAlignment === PageAlignment.center,
      })}
    >
      <SectionHeader
        title={t('app.widget.sections.account-settings.title')}
        subtitle={t('app.widget.sections.account-settings.subtitle')}
        className={classes.sectionHeader}
      />
      <Accordion>
        {isPublicProfile(privacyStatus) && <CommunityPageUrl />}
        {!isOwner(role) && isCustomProfileEnabled && <ProfileVisibility />}
      </Accordion>
    </div>
  );
};
