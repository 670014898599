import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TextFieldTheme } from 'wix-ui-tpa/TextField';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { classes, st } from './FieldGroup.st.css';
import { Field, FieldSection, FieldType } from '../../../../types';
import { Text } from '../common/Fields/Text';
import { DatePicker } from '../common/Fields/Date';
import settingsParams from '../../settingsParams';
import { UrlField } from '../common/Fields/Url';
import { NumberField } from '../common/Fields/Number';
import { AddressField } from '../common/Fields/Address';
import {
  BIRTHDAY_FIELD_KEY,
  EMAILS_FIELD_KEY,
  PHONES_FIELD_KEY,
} from '../../../../server/constants';

interface FieldGroupProps {
  fields: (Pick<Field, 'id' | 'type' | 'key'> &
    Partial<Pick<Field, 'section'>>)[];
  section?: FieldSection;
  fieldSelector?: string;
}

interface FieldListProps extends FieldGroupProps {
  fieldTheme: TextFieldTheme;
}

export const FieldList: FC<FieldListProps> = ({
  fields,
  fieldTheme,
  fieldSelector,
  section,
}) => (
  <>
    {fields.reduce<JSX.Element[]>((acc, field, fieldIndexInForm) => {
      if (section && field.section !== section) {
        return acc;
      }

      const { type, id, key } = field;
      const formikFieldSelector = `${fieldSelector ?? ''}[${fieldIndexInForm}]`;
      const props = { key: id, theme: fieldTheme, formikFieldSelector };

      let formElement: JSX.Element | undefined;
      if (type === FieldType.TEXT) {
        formElement = <Text {...props} />;
      } else if (key === BIRTHDAY_FIELD_KEY) {
        formElement = <DatePicker {...props} allowFutureDates={false} />;
      } else if (key === EMAILS_FIELD_KEY) {
        formElement = <Text {...props} type="email" />;
      } else if (key === PHONES_FIELD_KEY) {
        formElement = <Text {...props} type="tel" />;
      } else if (type === FieldType.DATE) {
        formElement = <DatePicker {...props} />;
      } else if (type === FieldType.NUMBER) {
        formElement = <NumberField {...props} />;
      } else if (type === FieldType.ADDRESS) {
        formElement = <AddressField {...props} />;
      } else if ([FieldType.URL, FieldType.SOCIAL].includes(type)) {
        formElement = <UrlField {...props} />;
      }

      return formElement ? [...acc, formElement] : acc;
    }, [])}
  </>
);

export const FieldGroup: FC<FieldGroupProps> = ({
  fields,
  section,
  fieldSelector,
}) => {
  const settings = useSettings();
  const fieldTheme = settings.get(
    settingsParams.inputFieldsTheme,
  ) as TextFieldTheme;
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <FieldList
        fields={fields}
        fieldTheme={fieldTheme}
        section={section}
        fieldSelector={fieldSelector}
      />
    </div>
  );
};
