import React, { FC } from 'react';

import { classes } from './Accordion.st.css';
import { Item } from './Item';

export const Accordion: FC & { Item: typeof Item } = ({ children }) => (
  <div className={classes.root}>{children}</div>
);

Accordion.Item = Item;
