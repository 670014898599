import React, { FC } from 'react';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Popover } from '../Popover';
import { classes } from './PrivacyIcon.st.css';
import { FieldPrivacy } from '../../../../../types';
import { LockIcon, PublicIcon } from '../icons';

type Props = {
  privacy: FieldPrivacy;
  onClick?(): void;
  disabled?: boolean;
  'data-hook'?: string;
};

const tooltipText = {
  [FieldPrivacy.PRIVATE]: 'app.widget.fields.private',
  [FieldPrivacy.PUBLIC]: 'app.widget.fields.public',
  [FieldPrivacy.UNKNOWN]: 'app.widget.fields.public',
};

const tooltipIcon = {
  [FieldPrivacy.PRIVATE]: <LockIcon />,
  [FieldPrivacy.PUBLIC]: <PublicIcon />,
  [FieldPrivacy.UNKNOWN]: <PublicIcon />,
};

export const PrivacyIcon: FC<Props> = ({
  privacy,
  onClick,
  'data-hook': dataHook,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      popoverContent={t(tooltipText[privacy])}
      className={classes.popover}
    >
      <IconButton
        className={classes.iconButton}
        skin={Skins.Full}
        icon={tooltipIcon[privacy]}
        onClick={onClick}
        disabled={disabled}
        data-hook={dataHook}
      />
    </Popover>
  );
};
